import React, {ChangeEvent, useState} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel, Grid, TextField, Typography, CircularProgress
} from "@material-ui/core";
import IUser from "../Interfaces/IUser";
import {useHistory} from 'react-router-dom'
import axios from "axios";

interface Props {
    open: boolean;
    availableGroups: string[];
    handleClose: (user: IUserPartial | undefined) => void;
};

export interface IUserPartial {
    firstName: string,
    lastName: string,
    mail: string
}

const NewUserModal: React.FC<Props> = (props) => {
    const [user, setUser] = useState<IUserPartial>({
        firstName: "",
        lastName: "",
        mail: ""
    });
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);
    const history = useHistory();

    const isTicked = (group: string) => {
        return selectedGroups.filter(sGroup => sGroup === group).length > 0;
    };

    const toggleChecked = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedGroups([...selectedGroups, event.target.value]);
        } else {
            setSelectedGroups(selectedGroups.filter(sGroup => sGroup !== event.target.value));
        }
    };

    const handleDiscard = () => {
        setUser({
            firstName: "",
            lastName: "",
            mail: ""
        });
        setSelectedGroups([]);
        props.handleClose(undefined);
    };

    const handleSave = () => {
        if (selectedGroups.length === 0) {
            setError("Please select at least one group.")
            return;
        }
        if (user.firstName === "") {
            setError("Please enter a first name");
            return;
        }
        if (user.lastName === "") {
            setError("Please enter a last name");
            return;
        }
        if (user.mail === "") {
            setError("Please enter a valid email");
            return;
        }
        setSaving(true);
        const newUser = {firstName: user.firstName, lastName: user.lastName, mail: user.mail, groups: selectedGroups};
        axios.post(process.env.REACT_APP_API_LOCATION +"/api/user/create", newUser).then((data) => {
            setSaving(false);
            if (data.status === 200) {
                setUser({
                    firstName: "",
                    lastName: "",
                    mail: ""
                });
                setSelectedGroups([]);
                setError("");
                props.handleClose(undefined);
            }
        }).catch(err => {
            setSaving(false);
            switch (err.response.status) {
                case 400:
                    setError("Error: " + err.response.data.message);
                    break;
                case 401:
                    history.push("/login");
                    break;
                case 500:
                default:
                    setError("An internal server error has occured, please contact your administrator.")
                break;
            }
        })

    };

    return <Dialog open={props.open} onClose={handleDiscard} maxWidth={"lg"}>
        <DialogTitle id="form-dialog-title">Create New User</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Grid container direction={"column"} justify={"space-around"} alignItems={"center"} spacing={2} style={{minWidth: '600px'}}>
                    <Grid xs={12} item><TextField label={"First Name"} id="outlined-basic" variant="outlined" value={user?.firstName} onChange={(event => setUser({...user, firstName: event.target.value}))}/></Grid>
                    <Grid xs={12} item><TextField label={"Last Name"} id="outlined-basic" variant="outlined" value={user?.lastName} onChange={(event => setUser({...user, lastName: event.target.value}))}/></Grid>
                    <Grid xs={12} item><TextField label={"Email"} id="outlined-basic" variant="outlined" value={user?.mail} onChange={(event => setUser({...user, mail: event.target.value}))}/></Grid>
                    <Grid xs={12}  item><Typography>Groups:</Typography></Grid>
                    {props.availableGroups.map((group,index) => {
                        return <Grid key={"grid-fcl-"+index} xs={6} item><FormControlLabel key={"fcl-"+index} control={<Checkbox value={group} checked={isTicked(group)} onChange={(event) => toggleChecked(event)} />} label={group} /></Grid>
                    })}
                </Grid>
                {error ? <Typography variant={'subtitle2'} style={{color: '#FF5087', marginTop:'25px'}}>{error}</Typography> : <></>}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDiscard} color="primary">
                Cancel
            </Button>
            <Button onClick={handleSave} color="primary" variant={"contained"}>
                {saving ? <CircularProgress style={{color: 'white'}} size={24}/> : "Save" } 
            </Button>
        </DialogActions>
    </Dialog>
}

export default NewUserModal;
