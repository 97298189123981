import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import 'typeface-roboto';
import './App.css';
import Main from "./Components/Main";

const App: React.FC = () => {
  return (
    <div className="App">
        <Router>
            <Main/>
        </Router>
    </div>
  );



}

export default App;
