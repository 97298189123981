import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import axios from "axios";
import {
    createStyles,
    Fab, Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell, TableFooter,
    TableHead, TablePagination,
    TableRow, TextField, Theme, Typography, Snackbar, Button
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import IUser from "../Interfaces/IUser";
import IGroup from "../Interfaces/IGroup";

import UsersTableRow from "./UsersTableRow";
import EditGroupsModal from "./EditGroupsModal";
import NewUserModal, {IUserPartial} from "./NewUserModal";
import SearchIcon from '@material-ui/icons/Search';
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import ResetPasswordModal from "./ResetPasswordModal";
import DeleteUserModal from "./DeleteUserModal";
import ResetMFAModal from "./ResetMFAModal";
import {SpeedDial, SpeedDialAction} from "@material-ui/lab";
import BulkAddUserGroupModal from "./BulkAddUserGroupModal";
import BulkNewUserModal from "./BulkNewUserModal";

interface Props {};

const useStyles = makeStyles((theme: Theme) => createStyles({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const Users: React.FC<Props> = (props) => {
    const classes = useStyles();
    const [users, setUsers] = useState<Array<IUser>>([]);
    const [userForEditingGroups, setUserForEditingGroups] = useState<IUser>();
    const [groups, setGroups] = useState<Array<string>>([]);
    const [showGroupsModal, setShowGroupsModal] = useState(false);
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [usersPerPage, setUsersPerPage] = useState(15);
    const [page, setPage] = useState(0);
    const [searchFilter, setSearchFilter] = useState("");
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [userForDelete, setUserForDelete] = useState<IUser>();
    const [userForReset, setUserForReset] = useState<IUser>();
    const [showMFAResetModal, setShowMFAResetModal] = useState(false);
    const [userForMFA, setUserForMFA] = useState<IUser>();
    const [openSnack, setOpenSnack] = useState(false);
    const [snackErrorMessage, setSnackErrorMessage] = useState("");

    const [speedDialOpen, setSpeedDialOpen] = useState(false);
    
    const [showBulkNewUserModal, setShowBulkNewUserModal] = useState(false);
    
    
    const handleBulkNewUserModalClose = () => {
        setShowBulkNewUserModal(false);
        fetchUsers();
    }

    const openSpeedDial = () => {
        setSpeedDialOpen(true);
    };

    const closeSpeedDial = () => {
        setSpeedDialOpen(false);
    };


    const history = useHistory();

    useEffect(() => {
        fetchUsers();
        fetchGroups();
    }, []);



    const fetchUsers = () => {
        axios.get(process.env.REACT_APP_API_LOCATION +"/api/user/all").then((data) => {
            if (data.status === 200) {
                setUsers(data.data);
            }
        }).catch(err => {
            const { response } = err;
            const { error, message } = response.data;
            const errMessage = (error === undefined) ? undefined : `Error: ${error}: ${message}`
            history.push({pathname: "/login", state: { error: errMessage, isLoggedIn: false }})
        })
    };

    const fetchGroups = () => {
        axios.get(process.env.REACT_APP_API_LOCATION +"/api/group/all").then((data) => {
            if (data.status === 200) {
                const groups: IGroup[] = data.data;
                let groupArray = new Array<string>();
                groups.forEach(g => groupArray.push(g.name));
                setGroups(groupArray);
            }
        }).catch(err => {
            console.log(err);
            history.push("/login")
        })
    };

    const toggleEditUserGroups = (user: IUser) => {
        setUserForEditingGroups(user);
        setShowGroupsModal(true);
    }

    const handleEditGroupsSave = (groups: string[] | null) => {
        console.log(groups);
        if (groups !== null) {
            axios.post(process.env.REACT_APP_API_LOCATION + "/api/user/groups", {user: userForEditingGroups, groups: groups}).then((data) => {
                if (data.status === 200) {
                    setShowGroupsModal(false);
                    fetchUsers();
                }
            }).catch(err => {
                console.log(err);
                // history.push("/login")
            });
        } else {
            setShowGroupsModal(false);
        }
    };

    const handleNewUserModalClose = (user: IUserPartial | undefined) => {
        setShowNewUserModal(false);
        fetchUsers();
    };

    const emptyRows = usersPerPage - Math.min(usersPerPage, users.length - page * usersPerPage);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setUsersPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,) => {
        setSearchFilter(event.target.value);
        setPage(0);
    };

    const usersFilter = () => {
        if (searchFilter === "") return users;
        const tUsers = users.filter(user => {
            const name = user.firstName + ' ' + user.lastName || "";
            const mail = user.mail || "";
            return name.toLowerCase().includes(searchFilter.toLowerCase()) //|| mail.toLowerCase().includes(searchFilter.toLowerCase())
      });
        return (tUsers === null) ? [] : tUsers;
    };

    const handleUserPasswordResetClose = (reset: boolean) => {
        if (reset) {
            axios.post(process.env.REACT_APP_API_LOCATION +"/api/user/resetPassword", userForReset).then((data) => {
                if (data.status === 200) {
                    setShowResetPasswordModal(false);
                    setUserForReset(undefined);
                    fetchUsers();
                }
            }).catch(err => {
                console.log(err);
                history.push("/login")
            })
        } else {
            setShowResetPasswordModal(false);
            setUserForReset(undefined);
        }
    }

    const handleUserDeleteClose = (del: boolean) => {
        if (del) {
            axios.post(process.env.REACT_APP_API_LOCATION +"/api/user/delete", userForDelete).then((data) => {
                if (data.status === 200) {
                    setShowDeleteUserModal(false);
                    setUserForDelete(undefined);
                    fetchUsers();
                }
            }).catch(err => {
                if (err.response.status === 400) {
                    setSnackErrorMessage(err.response.data.message);
                    setOpenSnack(true);
                } else if (err.response.status === 401) {
                    console.log(err);
                    history.push("/login")
                }
                setShowDeleteUserModal(false);
                setUserForDelete(undefined);
            })
        } else {
            setShowDeleteUserModal(false);
            setUserForDelete(undefined);
        }
        
    }

    const toggleUserDeleteModal = (user: IUser) => {
        setUserForDelete(user);
        setShowDeleteUserModal(true);
    }

    const toggleUserResetPasswordModal = (user: IUser) => {
        setUserForReset(user);
        setShowResetPasswordModal(true);
    }

    const toggleUserResetMFAModal = (user: IUser) => {
        setUserForMFA(user);
        setShowMFAResetModal(true);
    }

    const handleUserMFAResetClose = (del: boolean) => {
        if (del) {
            axios.post(process.env.REACT_APP_API_LOCATION +"/api/user/resetmfa", userForMFA).then((data) => {
                if (data.status === 200) {
                    setShowMFAResetModal(false);
                    setUserForMFA(undefined);
                    fetchUsers();
                }
            }).catch(err => {
                if (err.response.status === 400) {
                    setSnackErrorMessage(err.response.data.message);
                    setOpenSnack(true);
                } else if (err.response.status === 401) {
                    console.log(err);
                    history.push("/login")
                }
                setShowMFAResetModal(false);
                setUserForMFA(undefined);
            })
        } else {
            setShowMFAResetModal(false);
            setUserForMFA(undefined);
        }
        
    }

    const handleSnackClose = () => {
        setSnackErrorMessage("");
        setOpenSnack(false);
    }


    return <div>
        <NewUserModal open={showNewUserModal} availableGroups={groups} handleClose={handleNewUserModalClose}/>
        <Paper style={{margin: '20px'}}>
        {userForEditingGroups !== undefined ? <EditGroupsModal availableGroups={groups} user={userForEditingGroups} open={showGroupsModal} handleClose={handleEditGroupsSave}/> : <></>}
        {userForReset !== undefined ? <ResetPasswordModal open={showResetPasswordModal} user={userForReset} handleClose={handleUserPasswordResetClose}/> : <></>}
        {userForDelete !== undefined ? <DeleteUserModal open={showDeleteUserModal} user={userForDelete} handleClose={handleUserDeleteClose}/> : <></>}
        {userForMFA !== undefined ? <ResetMFAModal open={showMFAResetModal} user={userForMFA} handleClose={handleUserMFAResetClose}/> : <></>}
        <BulkNewUserModal open={showBulkNewUserModal} availableGroups={groups} handleClose={handleBulkNewUserModalClose}/>
        
        <Grid alignItems={"center"} justify={"flex-end"} style={{paddingTop: '10px', paddingRight: '15px'}} container>
            <Paper>
                <Grid alignItems={"center"} style={{width: '250px', paddingLeft:'10px'}} container>
                    <Grid item><SearchIcon/></Grid>
                    <Grid item><TextField style={{width: '200px'}} value={searchFilter} onChange={handleSearchChange}>Search</TextField></Grid>
                </Grid>
            </Paper>
        </Grid>
        <Table size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell>User ID</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align={"center"}>Enabled</TableCell>
                    <TableCell align={"center"}>Groups</TableCell>
                    <TableCell align={"center"}>Edit</TableCell>
                    <TableCell align={"center"}>Reset Password</TableCell>
                    <TableCell align={"center"}>Reset 2FA</TableCell>
                    <TableCell align={"center"}>Delete</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {usersFilter().slice(page*usersPerPage,(page*usersPerPage)+usersPerPage).map((u, index) => {
                    return <UsersTableRow user={u} index={index} key={"user-table-row-"+index} 
                    triggerReload={fetchUsers} 
                    toggleEditUserGroups={toggleEditUserGroups} 
                    toggleDeleteUser={toggleUserDeleteModal} 
                    toggleResetUserPassword={toggleUserResetPasswordModal}
                    toggleResetUserMFA={toggleUserResetMFAModal}
                    />
                })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: 42 * emptyRows }}>
                        <TableCell colSpan={10}/>
                    </TableRow>
                )}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        colSpan={10}
                        count={usersFilter().length}
                        rowsPerPage={usersPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
            </TableFooter>
        </Table>
    </Paper>

        <SpeedDial className={classes.fab} icon={<AddIcon />} open={speedDialOpen}
                   ariaLabel={"Add New User(s)"} onOpen={openSpeedDial} onClose={closeSpeedDial}
                    direction={'up'}>
            <SpeedDialAction
                key={'addUserSpeedDial'}
                icon={<PersonAddIcon />}
                tooltipTitle={'Add New User'}
                onClick={(e) => setShowNewUserModal(true)}
            />
            <SpeedDialAction
                key={'addBlukUserSpeedDial'}
                icon={<GroupAddIcon />}
                tooltipTitle={'Bulk Add New Users'}
                onClick={(e) => setShowBulkNewUserModal(true)}
            />
        </SpeedDial>
        <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={openSnack}
        autoHideDuration={6000}
        message={snackErrorMessage}
        action={
          <>
            <Button color="secondary" size="small" onClick={handleSnackClose}>
              OK
            </Button>
          </>
        }
      />
    </div>
};

export default Users;
