import React, {useEffect, useRef} from 'react';
import axios from 'axios';
import {useHistory} from 'react-router-dom'
import MenuIcon from '@material-ui/icons/Menu';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PeopleIcon from '@material-ui/icons/People';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import FingerprintIcon from '@material-ui/icons/Fingerprint'

import {
    AppBar,
    Button,
    ClickAwayListener, Grow,
    IconButton,
    makeStyles,
    MenuItem,
    MenuList, Paper, Popper,
    Toolbar,
    Typography
} from "@material-ui/core";
import {Fingerprint} from "@material-ui/icons";

interface Props {
    isLoggedIn: boolean,
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const NavBar: React.FC<Props> = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [superUser, setSuperUser] = React.useState<boolean | undefined>(undefined);
    const [currentContext, setCurrentContext] = React.useState<string | undefined>(undefined)
    const anchorRef = useRef<HTMLButtonElement>(null);
    const prevOpen = React.useRef(open);
    const history = useHistory();

    React.useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        const doLogin = async () => {
            const res = await axios.get(process.env.REACT_APP_API_LOCATION +"/api/superuser/isSuperUser");
            if (res.status === 200) {
                setSuperUser(true);
                const contextRes = await axios.get(process.env.REACT_APP_API_LOCATION +"/api/superuser/getCurrentContext")
                setCurrentContext(contextRes.data)
            }
        }

        if (props.isLoggedIn && superUser == undefined) {
            doLogin()
        }
    }, [props.isLoggedIn])

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>, path: string) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        if (path !== "") history.push(path);
        setOpen(false);
    };

    const buttonLogout = () => {
        axios.get(process.env.REACT_APP_API_LOCATION +"/logout").then((data) => {
            if (data.status === 200) {
                history.push('/login')
                window.location.reload();
            }
        }).catch(err => {
        })
    };

    const sideBarButton = () => {
        return <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" ref={anchorRef} onClick={handleToggle}>
            <MenuIcon />
        </IconButton>;
    };

    const logoutButton = () => {
        return <Button color="inherit" onClick={buttonLogout}>Logout</Button>;
    };

    const popUpMenuPopper = () => {
        return <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={(event) => handleClose(event, "")}>
                            <MenuList autoFocusItem={open} id="menu-list-grow">
                                {/* <MenuItem onClick={(event) => handleClose(event, "/profile")}><AccountBoxIcon/>Account</MenuItem> */}
                                <MenuItem onClick={(event) => handleClose(event, "/users")}><PeopleIcon/>Users</MenuItem>
                                <MenuItem onClick={(event) => handleClose(event, "/groups")}><GroupWorkIcon/>Groups</MenuItem>
                                { superUser && <MenuItem onClick={(event) => handleClose(event, "/superuser")}><FingerprintIcon/>Super User</MenuItem> }
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>;

    };

    const navigateBackToMain = () => {
        history.push("/users");
    }

    const parseCurrentContext = () => currentContext !== undefined ? ": " + currentContext : "";

    const appbar = () => {
        return <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    {props.isLoggedIn ? sideBarButton() : <></>}
                    {popUpMenuPopper()}
                    <Typography variant="h6" className={classes.title} onClick={() => navigateBackToMain()}>
                        iSpot Authentication Administration Module {parseCurrentContext()}
                    </Typography>
                    {props.isLoggedIn ? logoutButton() : <></>}
                </Toolbar>
            </AppBar>
        </div> 
    };

    return appbar();
};

export default NavBar;
