import React, {FormEvent, useState} from 'react';
import {Button, Grid, Input, makeStyles, Paper, Typography, TextField, StylesProvider, Fade, CircularProgress} from "@material-ui/core";
import BathtubIcon from '@material-ui/icons/Bathtub';
import SecurityIcon from '@material-ui/icons/Security';
import { useHistory } from 'react-router-dom';

interface Props {
    login: (username: string, password: string) => void;
    errorMessage: string;
};

const useStyles = makeStyles((theme) => ({
    paperBox: {
        margin: '40px',
        padding: '40px',
        width: '400px',
    },
    icon: {
        padding: '20px'
    },
    input: {
        width: '250px'
    },
    button: {
        width: '200px'
    },
    errorMessage: {
        color: 'red',
        marginTop: '40px'
    },
    spinner: {
        color: 'white'
    }
}));

const Login: React.FC<Props> = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [form, setForm] = useState({
        username: "",
        password: ""
    });

    const [loading, setLoading] = useState(false);

    const clearForm = () => {
        setForm({
            username: "",
            password: ""
        });
    };

    const pushToResetPassword = () => {
        history.push("/resetpassword");
    }

    const tryLogin = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        props.login(form.username, form.password);
    };


    const loginForm = () => {
        return (
            <Fade in={true} timeout={1000}>
            <Grid container justify={"center"} alignItems={"center"} >
                <Grid item>
                <Paper className={classes.paperBox}>
                    <form onSubmit={(event) => tryLogin(event)}>
                        <Grid container direction={"column"} justify={"space-around"} alignItems={"center"} spacing={4}>
                            <Grid item container direction={"row"} justify={"center"} alignItems={"center"}>
                                <SecurityIcon fontSize="large" className={classes.icon}/>
                                <Typography variant={"h4"}>iSpot Administration</Typography>
                            </Grid>
                            <Grid item><Typography>Welcome back. Please sign in to continue.</Typography></Grid>
                            <Grid item><TextField className={classes.input} variant={"outlined"} label={"Username"} value={form.username} onChange={(event) => {setForm({...form, username: event.target.value})}}/></Grid>
                            <Grid item><TextField className={classes.input} variant={"outlined"} label={"Password"} type="password" value={form.password} onChange={(event) => {setForm({...form, password: event.target.value})}}/></Grid>
                            <Grid container item direction={"column"} justify={"space-around"} alignItems={"center"} spacing={2}>
                            <Grid item>{loading && props.errorMessage === "" ? <Button className={classes.button} color={"primary"} variant={"contained"} type={"submit"}><CircularProgress size={24} className={classes.spinner} /></Button> : <Button className={classes.button} color={"primary"} variant={"contained"} type={"submit"}>Login</Button>}</Grid>
                                {/* <Grid item><Button variant={"outlined"} onClick={() => clearForm()}>Clear</Button></Grid> */}
                                <Grid item><Button className={classes.button} variant={"outlined"} onClick={() => pushToResetPassword()}>Reset Password</Button></Grid>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid item><Typography className={classes.errorMessage} align={"center"}>{props.errorMessage}</Typography></Grid>
                </Paper>
                </Grid>
            </Grid>
            </Fade>
        )
    };

    return loginForm();
};

export default Login;
