import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel
} from "@material-ui/core";
import IUser from "../Interfaces/IUser";


interface Props {
    open: boolean;
    user: IUser;
    handleClose: (del: boolean) => void;
}

const DeleteUserModal: React.FC<Props> = (props) => {
    return <Dialog open={props.open} onClose={() => props.handleClose(false)}>
    <DialogTitle id="form-dialog-title">Delete User's Account</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Are you sure you want to delete user {props.user.firstName + " " + props.user.lastName}?
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => props.handleClose(false)} color="primary">
            Cancel
        </Button>
        <Button onClick={() => props.handleClose(true)} color="secondary" variant={"contained"}>
            Delete
        </Button>
    </DialogActions>
</Dialog>
}

export default DeleteUserModal;