import React, {ChangeEvent, useState} from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    CircularProgress,
    createStyles,
    Theme,
    Table,
    TableHead,
    TableRow,
    TableCell, TableBody
} from "@material-ui/core";
import IUser from "../Interfaces/IUser";
import {useHistory} from 'react-router-dom'
import axios from "axios";
import {green, pink, red} from "@material-ui/core/colors";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

interface Props {
    open: boolean;
    availableGroups: string[];
    handleClose: () => void;
};

export interface IUserPartial {
    firstName: string,
    lastName: string,
    mail: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        rightIcon: {
            marginLeft: theme.spacing(1),
        },
        iconSmall: {
            fontSize: 20,
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            }
        },
        buttonFailure: {
            backgroundColor: red[500],
            '&:hover': {
                backgroundColor: red[700],
            }
        },
        buttonProgress: {
            color: pink[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        errorText: {
            color: red[500]
        }
    }),
);

interface BulkUserResult {
    email: string;
    success: boolean;
    message: string;
}

const BulkNewUserModal: React.FC<Props> = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        loading: false,
        success: false,
        failure: false
    });
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [userResult, setUserResult] = useState<BulkUserResult[]>([]);

    const history = useHistory();

    const fileUpload = React.createRef<HTMLInputElement>();

    const handleDiscard = () => {
        setState({
            loading: false,
            success: false,
            failure: false
        });
        setError("");
        setMessage("");
        setUserResult([])
        props.handleClose();
    };

    const checkSuccessfulLoad = (users: BulkUserResult[]) => {
        let success = true
        users.forEach(user => {
            if (!user.success) success = false;
        })
        return success;
    }

    const successCount = (users: BulkUserResult[]) => {
        let count = 0;
        users.map(user => {
            if (user.success) count++;
        })
        return count;
    }

    const failureCount = (users: BulkUserResult[]) => {
        return users.length - successCount(users);
    }


    const handleFileUpload = () => {
        const node = fileUpload.current;
        if (node !== null) {
            if (node.files && node.files.length > 0) {
                const file = node.files[0];
                setMessage("Attempting to upload " + file.name + ", do not navigate away from this page until the process is complete .");
                const formData = new FormData();
                formData.append('file',file);
                axios.post(process.env.REACT_APP_API_LOCATION + '/api/user/bulkcreate', formData)
                    .then((response) => {
                        console.log(response);
                        const count = response.data.length;
                        let result = checkSuccessfulLoad(response.data);
                        setUserResult([...response.data]);
                        if (result) {
                            setMessage(`Successfully loaded ${count} users.`)
                            setState({...state, loading: false, success: true, failure: false})
                        } else {
                            setMessage(`Attempted to load ${count} users, there were ${successCount(response.data)} users created and ${failureCount(response.data)} failures.`)
                            setState({...state, loading: false, success: false, failure: true})
                        }
                    })
                    .catch((err) => {
                        switch (err.response.status) {
                            case 400:
                                setError("Error: " + err.response.data.message);
                                setState({...state, loading: false, success: false, failure: true});
                                break;
                            case 401:
                                history.push("/login");
                                setState({...state, loading: false, success: false, failure: true});
                                break;
                            case 500:
                            default:
                                setError("An internal server error has occured, please contact your administrator.");
                                setState({...state, loading: false, success: false, failure: true});
                                break;
                        }
                    });
                setState({...state, loading: true, failure: false, success:false});
            } else {
                setError("No file selected!")
                setState({...state, failure: true})
            }
        }

    }

    const buttonClassname = clsx({
        [classes.button]: true,
        [classes.buttonSuccess]: state.success,
        [classes.buttonFailure]: state.failure
    })

    return <Dialog open={props.open} onClose={handleDiscard} maxWidth={"lg"}>
        <DialogTitle id="form-dialog-title">Bulk Add New User</DialogTitle>
        <DialogContent>
            <DialogContentText>Upload a CSV file - Must include the following columns (with no spaces):</DialogContentText>
            <DialogContentText><b>email,first_name,last_name,groups</b></DialogContentText>
            <DialogContentText>Available Groups: <b>{props.availableGroups.toString()}</b></DialogContentText>
            <DialogContentText>Groups to be passed in as a comma separated 'array' surrounded in quotes e.g.</DialogContentText>
            <DialogContentText> <b>"Group 1,Group B,Group3"</b> </DialogContentText>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item><input id="button-file" type="file" accept="csv" ref={fileUpload}/></Grid>
                <Grid item>
                    <div className={classes.wrapper}>
                        <Button variant="contained" color="primary" component="span" className={buttonClassname} onClick={handleFileUpload}>
                            Upload <CloudUploadIcon className={classes.rightIcon}/>
                        </Button>
                        {state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </Grid>
            </Grid>
            <DialogContentText>
                {message !== "" ? <DialogContentText >{message}</DialogContentText> : null}
            </DialogContentText>
            <DialogContentText>
                {error !== "" ? <DialogContentText className={classes.errorText}>{error}</DialogContentText> : null}
            </DialogContentText>
            {userResult.length > 0 && state.failure ?
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User email</TableCell>
                            <TableCell>Result</TableCell>
                            <TableCell>Message</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userResult.map((row, index) => {
                            if (!row.success) {
                                return(
                                    <TableRow key={index}>
                                        <TableCell>{row.email}</TableCell>
                                        <TableCell>{row.success ? "Successful" : "Failure"}</TableCell>
                                        <TableCell>{row.success ? "-" : row.message}</TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                    </TableBody>
                </Table> : null}
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDiscard} color="primary">
                Exit
            </Button>
        </DialogActions>
    </Dialog>
}

export default BulkNewUserModal;
