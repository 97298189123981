import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, CircularProgress } from '@material-ui/core';
import IUser from '../Interfaces/IUser';
//import axios from 'axios';

interface Props { 
    open: boolean;
    user: IUser;
    handleClose: (reset: boolean) => void;
}

const ResetPasswordModal: React.FC<Props> = (props) => {
    return <Dialog open={props.open} onClose={(e) => props.handleClose(false)}>
    <DialogTitle id="form-dialog-title">Reset Users's Password</DialogTitle>
    <DialogContent>
        <DialogContentText>
            Are you sure you want to reset {props.user.firstName + ' ' + props.user.lastName}'s password?
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button color="primary" onClick={(e) => props.handleClose(false)}>
            Cancel
        </Button>
        <Button onClick={(e) => props.handleClose(true)} color="secondary" variant={"contained"}>
            {/* {isResetting ? <CircularProgress style={{color: 'white'}} size={24}/> : "Reset" }  */}
            Reset
        </Button>
    </DialogActions>
</Dialog>
}

export default ResetPasswordModal;