import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography } from '@material-ui/core';
import IGroup from '../Interfaces/IGroup';
//import axios from 'axios';

interface Props { 
    open: boolean;
    group: IGroup;
    handleClose: (del: boolean) => void;
}

const useStyles = makeStyles((theme) => createStyles({

}));

const DeleteGroupModal: React.FC<Props> = (props) => {
    const [state, setState] = useState();
    const classes = useStyles();
    useEffect(() => {}, []);

    return <Dialog open={props.open} onClose={() => props.handleClose(false)}>
    <DialogTitle id="form-dialog-title">Delete User's Account</DialogTitle>
    <DialogContent>
        <DialogContentText>
            <Typography>Are you sure you want to delete the group {props.group.name}?</Typography>
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => props.handleClose(false)} color="primary">
            Cancel
        </Button>
        <Button onClick={() => props.handleClose(true)} color="secondary" variant={"contained"}>
            Delete
        </Button>
    </DialogActions>
</Dialog>
}

export default DeleteGroupModal;