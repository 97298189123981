import React, { useState, useEffect, FormEvent } from 'react';
import {useHistory} from 'react-router-dom'
import { makeStyles, createStyles, Grid, Paper, Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import PhonelinkIcon from '@material-ui/icons/Phonelink';
import axios from 'axios';

interface Props { 

}

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        width: '40%',
        margin: '40px',
    },
    paperGrid: {
        marginTop: '40px',
        marginBottom: '40px'
    },
    errorText: {
        color: '#FF0604'
    },
    icon: {
        margin: '20px'
    },
    spinner: {
        color: 'white'
    },
    button: {
        width: '100px'
    }
}));

const TOTPLogin: React.FC<Props> = (props) => {
    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    // useEffect(() => {}, []);

    const tryLogin = () => {
        setLoading(true);
        axios.post(process.env.REACT_APP_API_LOCATION +"/api/totp/try", {code: code}).then((res) => {
            history.push("/users");
        }).catch((err) => {
            console.log(err.response);
            setError(err.response.data.message);
            setLoading(false);
        })
    }

    const submitTryLogin = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        tryLogin();
    }

    return <div>
                <Grid container justify={"center"} alignItems={"center"} spacing={2}>
                    <Paper className={classes.paper}>
                    <form onSubmit={(event) => submitTryLogin(event)}>
                        <Grid className={classes.paperGrid} container direction={"column"} justify={"center"} alignItems={"center"} spacing={3}>
                            <Grid container item direction={"row"} justify={"center"} alignItems={"center"} spacing={2}>
                                <PhonelinkIcon className={classes.icon}/><Typography variant={"h4"}>Two-Factor Login</Typography>
                            </Grid>
                            <Grid item><Typography align={"center"}>Please enter in a code from your Two Factor Authentication enabled device.</Typography></Grid>
                                <Grid item><TextField label="Code" variant="outlined" onChange={(e) => setCode(e.target.value)} value={code}></TextField></Grid>
                                {error === "" ? null : <Grid item><Typography align={"center"} className={classes.errorText}>{error}</Typography></Grid>}
                                <Grid item>{loading ? <Button className={classes.button} variant={"contained"} color={"primary"} onClick={tryLogin}><CircularProgress size={24} className={classes.spinner} /></Button> : <Button className={classes.button}  variant={"contained"} color={"primary"} onClick={tryLogin}>Submit</Button>}</Grid>
                        </Grid>
                        </form>

                    </Paper>
                </Grid>
            </div>
            
}

export default TOTPLogin;
