import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom'

import { makeStyles, createStyles, Button, CircularProgress, Table, Paper, TableHead, TableRow, TableCell, TableBody, TableFooter, Fab, TextField, Tooltip } from '@material-ui/core';
import IGroup from '../Interfaces/IGroup';
import CreateIcon from "@material-ui/icons/Create";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import axios from 'axios';

interface Props { 
    group: IGroup;
    triggerReload: () => void;
    deleteGroup: (group: IGroup) => void;
    bulkAddGroup: (group: IGroup) => void;
}

const useStyles = makeStyles((theme) => createStyles({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    clickable: {
        cursor: "pointer",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
        borderRadius: "150%",
        padding: "8px",
        '&:hover': {
            backgroundColor: "#FFEEF2"
        }
    },
    notClickable: {
        cursor: "not-allowed",
        color: "lightgrey",
        padding: "8px",
    },
}));

const GroupsTableRow: React.FC<Props> = (props) => {
    const classes = useStyles();
    const history = useHistory();


    const [group, setGroup] = useState<IGroup>();
    const [editMode, setEditMode] = useState(false);
    const [saving, setSaving] = useState(false);
    useEffect(() => {
        setGroup(props.group)
    }, [props.group]);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    }

    const saveGroup = () => {
        setSaving(true);
        axios.post(process.env.REACT_APP_API_LOCATION +"/api/group/edit", {previousName: props.group.name, group: group}).then((data) => {
            if (data.status === 200) {
                setSaving(false);
                setEditMode(false);
                props.triggerReload();
            }
        }).catch(err => {
            setSaving(false);
            setEditMode(false);
            console.log(err);
            history.push("/login")
        })
    }

    const cancelEdit = () => {
        setEditMode(false);
        setGroup(props.group);
    }

    const cannotEditBaseRoles = () => {
        return <Tooltip title="You may not edit the base groups.">
                <CreateIcon className={classes.notClickable} onClick={toggleEditMode}/>
            </Tooltip>
    }

    const cannotDeleteBaseGroups = () => {
        return <Tooltip title="You may not delete the base groups.">
                <DeleteForeverIcon className={classes.notClickable} onClick={toggleEditMode}/>
            </Tooltip>
    }

    const showDeleteGroupModal = () => {
        props.deleteGroup(props.group);
    }

    const showBulkAddGroupModal = () => {
        props.bulkAddGroup(props.group);
    }

    const viewRow = () => {
        return <TableRow>
                <TableCell>{props.group.name}</TableCell>
                <TableCell>{props.group.description ?? "-"}</TableCell>
                <TableCell align={"center"}>{(props.group.name === "ROLE_USER" || props.group.name === "ROLE_ADMIN") ? cannotEditBaseRoles() :<CreateIcon className={classes.clickable} onClick={toggleEditMode}/>}</TableCell>
                <TableCell align={"center"}><GroupAddIcon className={classes.clickable} onClick={showBulkAddGroupModal}/></TableCell>
                <TableCell align={"center"}>{(props.group.name === "ROLE_USER" || props.group.name === "ROLE_ADMIN") ? cannotDeleteBaseGroups() :<DeleteForeverIcon className={classes.clickable} onClick={showDeleteGroupModal}/>}</TableCell>
            </TableRow>
    }

    const editRow = () => {
        return (group !== undefined) 
        ? <TableRow>
            <TableCell><TextField variant="outlined" value={group.name} 
                onChange={(event) => setGroup({...group, name: event.target.value})}/></TableCell>
            <TableCell><TextField variant="outlined" value={group.description ?? ""} 
                onChange={(event) => setGroup({...group, description: event.target.value})}/></TableCell>
                    <TableCell align={"center"}>{saving ? <CircularProgress/> :<Button variant={"contained"} color={"primary"} size={"small"} startIcon={<SaveIcon />} onClick={saveGroup}> Save</Button>}</TableCell>
                    <TableCell align={"center"}><Button variant={"outlined"} color={"primary"} size={"small"} startIcon={<CancelIcon />} onClick={cancelEdit}> Cancel</Button></TableCell>
            <TableCell align={"center"}></TableCell>
        </TableRow>
        : <TableRow></TableRow>
    }

    return (editMode ? editRow() : viewRow() )
    
}

export default GroupsTableRow;