import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom'
import axios from 'axios';
import { makeStyles, Paper, createStyles, Grid, Typography, Input, Button, TextField } from '@material-ui/core';
import QRCode from 'qrcode';

interface Props { 

}

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        width: '50%',
        margin: '40px',
    },
    paperGrid: {
        marginTop: '40px',
        marginBottom: '40px'
    },
    errorText: {
        color: '#FF0604'
    },
}));

const TOTPRegister: React.FC<Props> = (props) => {
    const [barcodeURI, setBarcodeURI] = useState("");
    const [codes, setCodes] = useState({
        previous: "",
        current: ""
    })
    const [error, setError] = useState("");


    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_LOCATION +"/api/totp/initial").then((data) => {
            setBarcodeURI(data.data);
        }).catch((err) => {
            console.log(err);
        })
    }, []);
    useEffect(() => {
        if (barcodeURI.length > 0) QRCode.toCanvas(document.getElementById('barcode-qr-canvas'),barcodeURI);
    }, [barcodeURI])

    const tryRegistering = () => {
        axios.post(process.env.REACT_APP_API_LOCATION +"/api/totp/complete", codes).then((res) => {
            history.push("/totplogin")
        }).catch((err) => {
            console.log(err.response);
            setError(err.response.data.message);
        })
    }

    return <div>
            <Grid container justify={"center"} alignItems={"center"} spacing={2}>
                <Paper className={classes.paper}>
                <Grid className={classes.paperGrid} container direction={"column"} justify={"center"} alignItems={"center"} spacing={6}>
                    <Grid item><Typography variant={"h4"}>Two-Factor Registration</Typography></Grid>
                    <Grid item><Typography align={"center"}>Administrators must have 2FA enabled to use the system.<br/>Please register a device using the barcode below.<br/>You may use any app that supports TOTP, such as Google Authenicator, or Authy</Typography></Grid>
                    <Grid item><canvas id="barcode-qr-canvas"></canvas></Grid>
                    <Grid item><Typography align={"center"}>Please enter in the current and next codes after registering the above QRCode<br/>Codes must be in correct order and be the last two codes displayed by your app.</Typography></Grid>
                    <Grid container item direction={"row"} justify={"center"} alignItems={"center"} spacing={2}>
                        <Grid item><TextField label="First Code" variant="outlined" onChange={(e) => setCodes({...codes, previous: e.target.value})} value={codes.previous}></TextField></Grid>
                        <Grid item><TextField label="Second Code" variant="outlined" onChange={(e) => setCodes({...codes, current: e.target.value})} value={codes.current}></TextField></Grid>
                    </Grid>
                    {error === "" ? null : <Grid item><Typography align={"center"} className={classes.errorText}>{error}</Typography></Grid>}
                    <Grid item><Button variant={"contained"} color={"primary"} onClick={tryRegistering}>Submit</Button></Grid>
                </Grid>
                </Paper>
            </Grid>
        </div>
}

export default TOTPRegister;