import React, {useEffect, useState} from 'react';
import {TableCell, TableRow, TextField, CircularProgress, Checkbox, Tooltip, Button, makeStyles, createStyles} from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import PhonelinkOffIcon from '@material-ui/icons/PhonelinkOff';
import IUser from "../Interfaces/IUser";
import axios from "axios";
import {useHistory} from 'react-router-dom'

interface Props {
    user: IUser,
    index: number,
    toggleEditUserGroups: (user: IUser) => void;
    toggleDeleteUser: (user: IUser) => void;
    toggleResetUserPassword: (user: IUser) => void;
    toggleResetUserMFA: (user: IUser) => void;
    triggerReload: () => void;
};

const useStyles = makeStyles((theme) => createStyles({
    clickable: {
        cursor: "pointer",
        transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
        borderRadius: "150%",
        padding: "8px",
        '&:hover': {
            backgroundColor: "#FFEEF2"
        }
    },
}));


const UsersTableRow: React.FC<Props> = (props) => {
    const [user, setUser] = useState<IUser>();
    const [editMode, setEditMode] = useState(false);
    const [saving, setSaving] = useState(false);
    const [userToggle, setUserToggle] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        setUser(props.user)
    }, [props.user]);

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const toggleEditGroupsMode = () => {
        if (user !== undefined) props.toggleEditUserGroups(user);
    };

    const cancelEdit = () => {
        setEditMode(false);
        setUser(props.user);
    };

    const userEnabled = () =>{
        return (user?.useraccountcontrol === "512" || user?.useraccountcontrol == "66048") ? true : false;
    }

    const accountHasNotCompletedRegistration = () => {
        return (user?.useraccountcontrol === "546");
    }

    const enableDisableUser = () => {
        setUserToggle(true);
        if (user?.useraccountcontrol === "512" || user?.useraccountcontrol == "66048") {
            axios.post(process.env.REACT_APP_API_LOCATION +"/api/user/disable", user).then((res) => {
                setUserToggle(false);
                props.triggerReload();
            }).catch((err) => {
                console.log(err);
            });
        } else {
            axios.post(process.env.REACT_APP_API_LOCATION +"/api/user/enable", user).then((res) => {
                setUserToggle(false);
                props.triggerReload();  
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const accountEnabledCheckbox = () => {
        if (accountHasNotCompletedRegistration()) {
            return <Tooltip title="You cannot enable an account that has not fully completed the registration process">
                <Checkbox checked={false}></Checkbox>
            </Tooltip>
        }
        return <>{userToggle ?  <CircularProgress /> : <Checkbox checked={userEnabled()} onClick={(e) => enableDisableUser()}></Checkbox>}</>
    }

    const viewRow = () => {
        return (user !== undefined)
            ?   <TableRow key={"ut_" + props.index}>
                <TableCell>{user.userID}</TableCell>
                <TableCell>{user.firstName}</TableCell>
                <TableCell>{user.lastName}</TableCell>
                <TableCell>{user.mail}</TableCell>
                <TableCell align={"center"}>{accountEnabledCheckbox()}</TableCell>
                <TableCell align={"center"}><GroupAddIcon onClick={toggleEditGroupsMode} className={classes.clickable}/></TableCell>
                <TableCell align={"center"}><CreateIcon onClick={toggleEditMode} className={classes.clickable}/></TableCell>
                <TableCell align={"center"}><RotateLeftIcon onClick={(e) => props.toggleResetUserPassword(props.user)} className={classes.clickable}/></TableCell>
                <TableCell align={"center"}>{props.user.mfaEnabled ? <PhonelinkOffIcon onClick={(e) => props.toggleResetUserMFA(props.user)} className={classes.clickable}/> : <></>}</TableCell>
                <TableCell align={"center"}><DeleteForeverIcon onClick={(e) => props.toggleDeleteUser(props.user)} className={classes.clickable}/></TableCell>
            </TableRow>

            :   <TableRow></TableRow>
            ;};

    const saveUser = () => {
        setSaving(true);
        axios.post(process.env.REACT_APP_API_LOCATION +"/api/user/edit", user).then((data) => {
            if (data.status === 200) {
                setSaving(false);
                setEditMode(false);
                props.triggerReload();
            }
        }).catch(err => {
            setSaving(false);
            console.log(err);
            history.push("/login")
        })
    }

    const deleteUser = () => {

    }

    const editRow = () => {
        return (user !== undefined)
                ?   <TableRow style={{height: "120px"}} key={"ut_" + props.index}>
                    <TableCell>{user.userID}</TableCell>
                    <TableCell><TextField id="outlined-basic" variant="outlined" value={user?.firstName}
                                          onChange={(event => setUser({...user, firstName: event.target.value}))}/></TableCell>
                    <TableCell><TextField id="outlined-basic" variant="outlined" value={user?.lastName}
                                          onChange={(event => setUser({...user, lastName: event.target.value}))}/></TableCell>
                    <TableCell><TextField id="outlined-basic" variant="outlined" value={user?.mail}
                                          onChange={(event => setUser({...user, mail: event.target.value}))}/></TableCell>
                    <TableCell align={"center"}>{accountEnabledCheckbox()}</TableCell>
                    <TableCell align={"center"}><></></TableCell>
                    <TableCell align={"center"}><></></TableCell>
                    <TableCell align={"center"}>{saving ? <CircularProgress/> :<Button variant={"contained"} color={"primary"} size={"small"} startIcon={<SaveIcon />} onClick={saveUser}> Save</Button>}</TableCell>
                    <TableCell align={"center"}><Button variant={"outlined"} color={"primary"} size={"small"} startIcon={<CancelIcon />} onClick={cancelEdit}> Cancel</Button></TableCell>
                    <TableCell align={"center"}><></></TableCell>
                    </TableRow>

                :   <TableRow></TableRow>
        ;};

    return  editMode ? editRow() : viewRow();
};

export default UsersTableRow;
