import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, Switch, Route} from 'react-router-dom'
import axios from "axios";
import Login from "./Login";
import Profile from "./Profile";
import NavBar from "./NavBar";
import './css/main.css';
import Users from "./Users";
import Groups from "./Groups";
import CompleteRegistration from './CompleteRegistration';
import TOTPRegister from './TOTPRegister';
import TOTPLogin from './TOTPLogin';
import RequestPasswordReset from './RequestPasswordReset';
import ChangePassword from "./ChangePassword";
import SuperUser from "./SuperUser"

interface Props {};

const Main: React.FC<Props> = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [error, setError] = useState("");
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_LOCATION +"/isLoggedIn").then((res) => {
            if (res.status === 200) {
                setIsLoggedIn(true);
                console.log(res.data);
                if (res.data === true) {
                    history.push(history.location.pathname);
                } else {
                    const totpRegistrationStatusURL = process.env.REACT_APP_API_LOCATION + "/api/totp/status";
                    axios.get(totpRegistrationStatusURL).then((res) => {
                        if (res.data === true) {
                            history.push("/totplogin");
                        } else {
                            history.push("/totpregistration");
                        }
                    }).catch(err => {
                        const { response } = err;
                        const { error, message } = response.data;
                        const errMessage = (error === undefined) ? 'There was an error logging in.' : `Error: ${error}: ${message}`
                        setError(errMessage);
                        setIsLoggedIn(false);
                        document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
                    })
                }
            }
        }).catch(err => {
            console.log(err)
            setIsLoggedIn(false);
            if (location.pathname.startsWith("/complete") || location.pathname.startsWith("/reset") || location.pathname.startsWith("/confirmReset")) {
                //history.push(location.pathname);
                console.log(location);
            } else {
                history.push("login");
            }
        })
    }, [] );

    useEffect( () => {
        if (location.state !== undefined) {
            const { error, isLoggedIn } = location.state;
            setIsLoggedIn(isLoggedIn);
            if (error !== undefined) {
                setError(error);
            }
        }
    }, [location.state])

    const login = (username: string, password: string) => {
        setError("");
        const encodedUrl = process.env.REACT_APP_API_LOCATION + "/loginPost?f_username="+encodeURIComponent(username)+"&f_password="+encodeURIComponent(password);
        axios.post(encodedUrl).then((data) => {
            if (data.status === 200) {
                setIsLoggedIn(true);
                const totpRegistrationStatusURL = process.env.REACT_APP_API_LOCATION + "/api/totp/status";
                axios.get(totpRegistrationStatusURL).then((res) => {
                    if (res.data === true) {
                        history.push("/totplogin");
                    } else {
                        history.push("/totpregistration");
                    }
                }).catch(err => {
                    const { response } = err;
                    const { error, message } = response.data;
                    const errMessage = (error === undefined) ? 'There was an error logging in.' : `Error: ${error}: ${message}`
                    setError(errMessage);
                    setIsLoggedIn(false);
                    document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
                })
                //history.push("/users")
            }
        }).catch(err => {
            console.log(err.response.data);
            setError("Error logging in, please check your username and/or password");
            setIsLoggedIn(false);
            history.push("/login");
        })
    };

    return <div>
        <NavBar isLoggedIn={isLoggedIn} />
            <Route path={"/totplogin"}>
                <TOTPLogin />
            </Route>
            <Route path={"/totpregistration"}>
                <TOTPRegister />
            </Route>
            <Route path={"/login"}>
                <Login errorMessage={error} login={login}/>
            </Route>
            <Route path={"/profile"}>
                <Profile />
            </Route>
            <Route path={"/users"}>
                <Users />
            </Route>
            <Route path={"/groups"}>
                <Groups />
            </Route>
            <Route path={"/changepassword/user/:id/key/:reg"}>
                <ChangePassword/>
            </Route>
            <Route path={"/complete/user/:id/key/:reg"}>
                <CompleteRegistration/>
            </Route>
            <Route path={"/resetpassword"}>
                <RequestPasswordReset/>
            </Route>
            <Route path={"/superUser"}>
                <SuperUser/>
            </Route>
    </div>
};

export default Main;
// /complete/user/"+samAccountName+"/key/"+regKey+
