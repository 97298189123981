import React, {ChangeEvent, useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel
} from "@material-ui/core";
import IUser from "../Interfaces/IUser";

interface GroupsModalProps {
    open: boolean;
    handleClose: (groups: string[] | null) => void;
    user: IUser;
    availableGroups: string[]
};

const EditGroupsModal: React.FC<GroupsModalProps> = (props) => {
    const [selectedGroups, setSelectedGroups] = useState<string[]>([]);

    useEffect(() => {
        setSelectedGroups(props.user.memberOf)
    }, [props.user]);

    const isTicked = (group: string) => {
        return selectedGroups.filter(sGroup => sGroup === group).length > 0;
    };

    const toggleChecked = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedGroups([...selectedGroups, event.target.value]);
        } else {
            setSelectedGroups(selectedGroups.filter(sGroup => sGroup !== event.target.value));
        }
    };

    const handleDiscard = () => {
        setSelectedGroups(props.user.memberOf);
        props.handleClose(null);
    };

    const handleSave = () => {
        props.handleClose(selectedGroups);
    }

    return <Dialog open={props.open} onClose={handleDiscard}>
        <DialogTitle id="form-dialog-title">Edit User's AD Group Mapping</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.availableGroups.map((group) => {
                    return <FormControlLabel control={<Checkbox value={group} checked={isTicked(group)} onChange={(event) => toggleChecked(event)} />} label={group} />
                })}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDiscard} color="primary">
                Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
                Submit
            </Button>
        </DialogActions>
    </Dialog>
};

export default EditGroupsModal;
