import React, {useEffect, useState} from "react";
import {
    Button, Checkbox, Divider,
    FormControl, FormControlLabel, Grid, Input, InputAdornment,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import axios, {AxiosError} from "axios";

interface Props {
}

const useStyles = makeStyles((theme) => ({
    root: {},
    paperBox: {
        margin: '40px',
        padding: '40px',
        width: '400px',
    },
    icon: {
        padding: '20px'
    },
    input: {
        width: '250px'
    },
    button: {
        width: '200px'
    },
    errorMessage: {
        color: 'red',
        marginTop: '10px',
        lineBreak: 'anywhere',
        "&:nth-child(1)": {
            fontWeight: 'bold',
            marginBottom: '20px'
        }
    },
    infoMessage: {
        marginTop: '10px',
        lineBreak: 'anywhere',
        "&:nth-child(1)": {
            fontWeight: 'bold',
            marginBottom: '20px'
        }
    },
    spinner: {
        color: 'white'
    },
    gridItemTitle: {
        padding: '5px 0px'
    },
    gridItemFormTop: {
        padding: '40px 0px 0px 0px'
    },
    gridItemFormTopContext: {
        padding: '30px 0px 0px 0px'
    },
    formControl: {
        minWidth: '210px'
    },
    gridItem: {
        padding: '5px 0px',
        width: '200px'
    },
    gridItemSelect: {
        padding: '40px 0px 0px 0px'
    },
    gridItemButton: {
        padding: '42px 0px 0px 0px'
    }
}));

interface newOUForm {
    prefix: string,
    name: string,
    switchContext: boolean,
    password: string
}

interface messages {
    messages: string[],
    type: 'error' | 'info' | 'none'
}

const SuperUser: React.FC<Props> = (props) => {
    const [contexts, setContexts] = useState<string[]>([])
    const [context, setContext] = useState("")

    const [message, setMessage] = useState<messages>({
        messages: [],
        type: 'none'
    })

    const [newFormState, setNewFormState] = useState<newOUForm>({prefix: '', name: '', switchContext: false, password: ''})

    const classes = useStyles();

    useEffect(() => {
        fetchContexts();
    }, [])

    function errorHandler(e: any, openingMessage: string) {
        const {response} = e;
        const {error, message} = response.data;
        console.log(error, message)
        const errorMessage = [openingMessage,
            `${error}, ${message}.`,
            `Please try again, or raise a ticket with support`]
        setMessage({messages: errorMessage, type: "error"})
    }

    async function fetchContexts() {
        try {
            const contextsResp = await axios.get(process.env.REACT_APP_API_LOCATION + "/api/superuser/listAvailableContexts")
            const contextResp = await axios.get(process.env.REACT_APP_API_LOCATION + "/api/superuser/getCurrentContext")
            setContexts(contextsResp.data)
            setContext(contextResp.data)
        } catch (e) {
            errorHandler(e, `There was a problem fetching OUs`)
        }
    }

    const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => setContext(event.target.value as string)

    const handleContextChange = async () => {
        setMessage({messages: [], type: "none"})
        try {
            const res = await axios({
                method: 'post',
                url: process.env.REACT_APP_API_LOCATION + "/api/superuser/switchContext",
                data: context
            })
            if (res.status == 200) {
                setMessage({
                    messages: ["Successfully switched context, please refresh the page to log back in."],
                    type: "info"
                })
            }
        } catch (e) {
            errorHandler(e, `There was a problem switching contexts`)
        }
    }

    const isNewOUValid = () => {
        let errors: string[] = [];
        if (newFormState.prefix === '') {
            errors.push("Please enter a prefix")
        }
        if (newFormState.name === '') {
            errors.push("Please enter a name")
        }
        if (newFormState.password == '') {
            errors.push("Please enter a Service Account password")
        }
        if (errors.length > 0) {
            setMessage({messages: ["Cannot create OU:", ...errors], type: 'error'})
        }
        return (errors.length <= 0)
    }

    const handleNewOUCreate = async () => {
        setMessage({messages: [], type: 'none'})
        if (!isNewOUValid()) return;
        try {
            const res = await axios({
                method: 'post',
                url: process.env.REACT_APP_API_LOCATION + "/api/superuser/createOU",
                data: newFormState
            })
            if (res.status == 200) {
                setMessage({messages: syncScriptSuccessMessage(res.data), type: 'info'})
                await fetchContexts()
            }
        } catch (e) {
            errorHandler(e, `Could not create new Organizational Unit`)
        }
    }

    const syncScriptSuccessMessage = (serverMessage: string) => {
        return [
            serverMessage + '.',
            `The AD Sync script for this server should be amended as follows:`,
            `python3 /usr/local/scaligent/release/callosum/utilities/ldap_sync_python_api/syncUsersAndGroups.py script \\`,
            `--ts_hostport 'https://localhost:443' \\`,
            `--ts_uname 'tsadmin' \\`,
            `--ts_pass 'PASSWORD' \\`,
            `--ldap_hostport 'ldaps://msp-dc-01.ispot.zone:636' \\`,
            `--ldap_uname '${newFormState.name.toLowerCase()}.admin@ispot.zone' \\`,
            `--ldap_pass '${newFormState.password}' \\`,
            `--sync \\`,
            `--basedn 'ou=A8${newFormState.prefix}-${newFormState.name},dc=ispot,dc=zone'  \\`,
            `--filter_str '(&(!(CN=${newFormState.name.toUpperCase()}_ADMIN))(!(userPrincipalName=${newFormState.name.toLowerCase()}.admin@ispot.zone)))'  \\`,
            `--include_nontree_members --disable_ssl`,
        ]
    }

    const getMessageTypeClassname = () => message.type === 'error' ? classes.errorMessage : classes.infoMessage;

    const ResultsMessageBox = () => message.type === 'none' ? <Grid item xs={8}/> :
        <Grid item xs={8}>
            <Paper style={{margin: '25px', padding: '20px', maxWidth: '650px'}}>
                {message.messages.map(m => <Typography className={getMessageTypeClassname()}
                                                       align={"left"}>{m}</Typography>)}
            </Paper>
        </Grid>;

    return <div>
        <Grid container direction={"row"} justify={"flex-start"} alignItems={"flex-start"} spacing={1}>
            <Grid container item xs={4} direction={"column"} justify={"center"} alignItems={"stretch"} spacing={1}>

                <Grid item style={{padding: '10px'}}>
                    <Paper style={{margin: '20px', padding: '40px'}}>
                        <Grid container direction={"column"} alignItems={"flex-start"} justify={"space-evenly"}>
                            <Grid item className={classes.gridItemTitle}><Typography style={{fontWeight: 'bold'}}>Switch
                                Organizational Unit</Typography></Grid>
                            <Grid item className={classes.gridItemFormTopContext}><FormControl>
                                <InputLabel id="context-select-label">OU</InputLabel>
                                <Select
                                    labelId="context-select-label"
                                    id="context-select"
                                    value={context}
                                    onChange={handleSelectChange}
                                >
                                    {contexts.map(context => <MenuItem value={context}>{context}</MenuItem>)}
                                </Select>
                            </FormControl></Grid>
                            <Grid container alignItems={"center"} justify={"flex-end"}
                                  className={classes.gridItemButton}><Grid item><Button onClick={handleContextChange}
                                                                                        variant="contained"
                                                                                        color="primary">Switch</Button></Grid></Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item style={{padding: '10px'}}>
                    <Paper style={{margin: '20px', padding: '40px'}}>
                        <Grid container direction={"column"} alignItems={"flex-start"} justify={"space-evenly"}>
                            <Grid item className={classes.gridItemTitle}><Typography style={{fontWeight: 'bold'}}>Create
                                Organizational Unit</Typography></Grid>
                            <Divider/>
                            <Grid item className={classes.gridItemFormTop}><FormControl className={classes.formControl}>
                                <InputLabel>Prefix</InputLabel>
                                <Input id="prefix-basic" value={newFormState.prefix}
                                       startAdornment={<InputAdornment position="start">A8</InputAdornment>}
                                       onChange={(e) => setNewFormState({...newFormState, prefix: e.target.value})}/>
                            </FormControl></Grid>
                            <Grid item className={classes.gridItem}><FormControl className={classes.formControl}>
                                <InputLabel>Name</InputLabel>
                                <Input id="name-basic" value={newFormState.name}
                                       onChange={(e) => setNewFormState({...newFormState, name: e.target.value})}/>
                            </FormControl></Grid>
                            <Grid item className={classes.gridItem}><FormControl className={classes.formControl}>
                                <InputLabel>SA Password</InputLabel>
                                <Input id="password-basic" type="password" value={newFormState.password}
                                       onChange={(e) => setNewFormState({...newFormState, password: e.target.value})}/>
                            </FormControl></Grid>
                            <Divider/>
                            <Grid item className={classes.gridItemSelect}><FormControlLabel
                                control={
                                    <Checkbox
                                        checked={newFormState.switchContext}
                                        onChange={(e) => setNewFormState({
                                            ...newFormState,
                                            switchContext: e.target.checked
                                        })}
                                        name="Switch to this unit"
                                        color="primary"
                                    />
                                }
                                label="Switch to this unit on creation"
                            /></Grid>
                            <Grid container alignItems={"center"} justify={"flex-end"}
                                  className={classes.gridItemButton}><Grid item><Button onClick={handleNewOUCreate}
                                                                                        variant="contained"
                                                                                        color="primary">Create</Button></Grid></Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
            <ResultsMessageBox/>
        </Grid>
    </div>
}

export default SuperUser;