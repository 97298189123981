import React, { useState, FormEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Paper, Grid, Typography, Input, Button, makeStyles } from '@material-ui/core';
import axios from "axios";


interface Props {

}

interface Form {
    password: string,
    confirmPassword: string
}

const useStyles = makeStyles((theme) => ({
    paperBox: {
        margin: '40px',
        padding: '40px',
        width: '30%',
    },
    error: {
        color: "#FF5087",
        margin: '20px'
    },
    success: {
        color: "#99DF66",
        margin: '20px'
    }
}));

const CompleteRegistration: React.FC<Props>  = (props) => {
    const {id, reg} = useParams();

    const [form, setForm] = useState<Form>({password: '', confirmPassword: ''})
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [complete, setComplete] = useState(false);

    const classes = useStyles();
    const history = useHistory();

    const clearForm = () => {
        setForm({password: '', confirmPassword: ''});
    }

    const useEffect = () => {

    }

    const trySubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        axios.post(process.env.REACT_APP_API_LOCATION + "/api/user/complete", {id: id, regkey: reg, password: form.password}).then((data) => {
            if (data.status === 200) {
                setComplete(true);
            }
        }).catch(err => {
            switch (err.response.status) {
                case 400:
                    setErrorMessage("Error: " + err.response.data.message);
                    break;
                case 401:
                    history.push("/login");
                    break;
                case 500:
                default:
                    setErrorMessage("An internal server error has occured, please contact your administrator.")
                break;
            }
        })
    };

    const buttonEnabled = () => {
        if (form.password.length <= 0) return true;
        return (!(form.password === form.confirmPassword)) ? true : false;
    }

    const returnToLogin = () => {
        history.push("/login");
    }


    return (!complete ?  <Paper className={classes.paperBox}>
    <form onSubmit={(event) => trySubmit(event)}>
        <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={4}>
            <Grid item><Typography>Email:</Typography> <Input disabled value={id}/></Grid>
            <Grid item><Typography>Registration Key:</Typography> <Input disabled value={reg} /></Grid>
            <Grid item><Typography>Password:</Typography> <Input type="password"  value={form.password} onChange={(event) => {setForm({...form, password: event.target.value})}}/></Grid>
            <Grid item><Typography>Confirm Password:</Typography><Input type="password" value={form.confirmPassword} onChange={(event) => {setForm({...form, confirmPassword: event.target.value})}}/></Grid>
            <Grid container direction={"row"} justify={"center"} alignContent={"space-between"} spacing={2} item>
                <Grid item><Button disabled={buttonEnabled()} color={"primary"} variant={"contained"} type={"submit"}>Complete Registration</Button></Grid>
                <Grid item><Button variant={"outlined"} onClick={() => clearForm()}>Clear</Button></Grid>
            </Grid>
        </Grid>
    </form>
    <Typography className={classes.error}>{errorMessage}</Typography>
    <Typography className={classes.success}>{successMessage}</Typography>
</Paper>
            : <Paper className={classes.paperBox}>
                <Grid container direction={"column"} justify={"center"} alignItems={"center"} spacing={4}>
                    <Grid item><Typography>User registration was successfully completed. You may now close this page.</Typography></Grid>
                    {/*<Grid item><Typography className={classes.success}><Button variant={"contained"} color={"primary"} onClick={returnToLogin}>Return to Login</Button></Typography></Grid>*/}
                </Grid>
            </Paper>

    )
}

export default CompleteRegistration;
