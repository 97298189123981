import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, Paper, Grid, Typography, TextField, Button } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

interface Props { 

}

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        width: '40%',
        margin: '40px',
    },
    paperGrid: {
        marginTop: '40px',
        marginBottom: '40px'
    },
    errorText: {
        color: '#FF0604'
    },
    icon: {
        margin: '20px'
    },
}));

const RequestPasswordReset: React.FC<Props> = (props) => {
    const [complete, setComplete] = useState(false);
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const classes = useStyles();
    const history = useHistory();


    const tryReset = () => {
        setComplete(true);
        // post, don't care about the result.   
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_LOCATION + "/api/user/requestpasswordreset",
            data: email
        });
    }

    const returnToLogin = () => {
        history.push("/login");
    }

    return <div>
    <Grid container justify={"center"} alignItems={"center"}>
        <Paper className={classes.paper}>
            {
                complete ?
                    <Grid className={classes.paperGrid} container direction={"column"} justify={"center"} alignItems={"center"} spacing={3}>
                        <Grid container item direction={"row"} justify={"center"} alignItems={"center"} spacing={2}>
                            <RotateLeftIcon className={classes.icon} fontSize={"large"}/><Typography variant={"h4"}>Request Password Reset</Typography>
                        </Grid>
                        <Grid item><Typography align={"center"} style={{padding: '40px'}}>Thank you for your request. You should receive an email detailing how to finish the process shortly.</Typography></Grid>
                        {error === "" ? null : <Grid item><Typography align={"center"} className={classes.errorText}>{error}</Typography></Grid>}
                        <Grid item><Button variant={"contained"} color={"primary"} onClick={returnToLogin}>Return to Login</Button></Grid>
                    </Grid>
                : 
                    <Grid className={classes.paperGrid} container direction={"column"} justify={"center"} alignItems={"center"} spacing={3}>
                        <Grid container item direction={"row"} justify={"center"} alignItems={"center"} spacing={2}>
                            <RotateLeftIcon className={classes.icon} fontSize={"large"}/>
                            <Typography variant={"h4"}>Request Password Reset</Typography>
                        </Grid>
                        <Grid item><Typography align={"center"}>Please enter in your email or user ID:</Typography></Grid>
                        <Grid item><TextField label="Email/User ID" variant="outlined" onChange={(e) => setEmail(e.target.value)} value={email}></TextField></Grid>
                        {error === "" ? null : <Grid item><Typography align={"center"} className={classes.errorText}>{error}</Typography></Grid>}
                        <Grid item><Button variant={"contained"} color={"primary"} onClick={tryReset}>Submit</Button></Grid>
                    </Grid>
            }
            
        </Paper>
    </Grid>
</div>
}

export default RequestPasswordReset;
