import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom'

import { makeStyles, createStyles, Dialog, DialogTitle, DialogContent, DialogContentText, Grid, TextField, Typography, DialogActions, Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';

import IGroup from "../Interfaces/IGroup";


interface Props { 
    open: boolean;
    handleClose: () => void;
}

const useStyles = makeStyles((theme) => createStyles({

}));

interface IGroupPartial {
    name: String;
    description: String;
}


const NewGroupModal: React.FC<Props> = (props) => {
    const [group, setGroup] = useState<IGroupPartial>({
       name: "",
       description: "",
    })
    const [error, setError] = useState("");
    const [saving, setSaving] = useState(false);

    const history = useHistory();

    const handleSave = () => {
        if (group.name.length <= 0) {
            setError("Please enter a name.");
            return;
        }
        if (group.description.length <= 0) {
            setError("Please enter a description.")
            return;
        }
        setSaving(true);
        axios.post(process.env.REACT_APP_API_LOCATION +"/api/group/create", group).then((data) => {
            setSaving(false);
            if (data.status === 200) {
                setGroup({
                    name: "",
                    description: "",
                })
                props.handleClose();
            }
        }).catch(err => {
            setSaving(false);
            switch (err.response.status) {
                case 400:
                    setError("Error: " + err.response.data.message);
                    break;
                case 401:
                    history.push("/login");
                    break;
                case 500:
                default:
                    setError("An internal server error has occured, please contact your administrator.")
                break;
            }
        })
    }
    
    const handleDiscard = () => {
        setGroup({
            name: "",
            description: "",
        })
        props.handleClose();
    }

    const classes = useStyles();

    return <Dialog open={props.open} onClose={handleDiscard}>
    <DialogTitle id="form-dialog-title">Create New Group</DialogTitle>
    <DialogContent>
        <DialogContentText>
            <Grid container direction={"column"} justify={"space-around"} alignItems={"center"} spacing={2}>
                <Grid xs={12} item><TextField label={"Name"} id="outlined-basic" variant="outlined" value={group?.name} onChange={(event => setGroup({...group, name: event.target.value}))}/></Grid>
                <Grid xs={12} item><TextField label={"Description"} id="outlined-basic" variant="outlined" value={group?.description} onChange={(event => setGroup({...group, description: event.target.value}))}/></Grid>
            {error ? <Typography variant={'subtitle2'} style={{color: '#FF5087', marginTop:'25px'}}>{error}</Typography> : <></>}
            </Grid>
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleDiscard} color="primary">
            Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant={"contained"}>
            {saving ? <CircularProgress style={{color: 'white'}} size={24}/> : "Save" } 
        </Button>
    </DialogActions>
</Dialog>
}

export default NewGroupModal;