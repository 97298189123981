import React, {useState, useEffect} from "react";
import {useHistory} from 'react-router-dom'
import axios from "axios";

import { createStyles, makeStyles, Paper, Grid, TextField, Table, TableHead, TableRow, TableCell, TableBody, TableFooter, Fab, TablePagination } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import IUser from "../Interfaces/IUser";
import IGroup from "../Interfaces/IGroup";
import GroupsTableRow from "./GroupsTableRow";
import NewGroupModal from "./NewGroupModal";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import DeleteGroupModal from "./DeleteGroupModal";
import BulkAddUserGroupModal from "./BulkAddUserGroupModal";

interface Props {};


const useStyles = makeStyles((theme) => createStyles({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));



const Groups: React.FC<Props> = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [searchFilter, setSearchFilter] = useState("");
    const [showNewGroupModal, setShowNewGroupModal] = useState(false);
    const [users, setUsers] = useState<Array<IUser>>([]);
    const [groups, setGroups] = useState<Array<IGroup>>([]);
    const [page, setPage] = useState(0);
    const [groupsPerPage, setGroupsPerPage] = useState(15);

    const [groupForDelete, setGroupForDelete] = useState<IGroup>();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [groupForUsers, setGroupForUsers] = useState<IGroup>();
    const [showBulkUserModal, setShowBulkUserModal] = useState(false);

    useEffect(() => {
        fetchUsers();
        fetchGroups();
    }, []);



    const fetchUsers = () => {
        axios.get(process.env.REACT_APP_API_LOCATION +"/api/user/all").then((data) => {
            if (data.status === 200) {
                setUsers(data.data);
            }
        }).catch(err => {
            console.log(err);
            history.push("/login")
        })
    };

    const fetchGroups = () => {
        axios.get(process.env.REACT_APP_API_LOCATION +"/api/group/all").then((data) => {
            if (data.status === 200) {
                setGroups(data.data);
            }
        }).catch(err => {
            console.log(err);
            history.push("/login")
        })
    };

    const groupsFilter = () => {
        if (searchFilter === "") return groups;
        const tGroups = groups.filter(group => {
            const tName = group.name || "";
            const tDesc = group.description || "";
            return (
                tName.toLowerCase().includes(searchFilter.toLowerCase()) || tDesc.toLowerCase().includes(searchFilter.toLowerCase())
        )});
        return tGroups ?? [];
    }

    const emptyRows = groupsPerPage - Math.min(groupsPerPage, groups.length - page * groupsPerPage);

    const handleNewGroupModalClose = () => {
        setShowNewGroupModal(false);
        fetchGroups();
    }

    const handleDeleteRowClicked = (group: IGroup) => {
        setGroupForDelete(group);
        setShowDeleteModal(true);
    }

    const handleDeleteGroupModal = (del: boolean) => {
        if (del) {
            axios.post(process.env.REACT_APP_API_LOCATION +"/api/group/delete", groupForDelete).then((data) => {
                if (data.status === 200) {
                    setShowDeleteModal(false);
                    setGroupForDelete(undefined);
                    fetchGroups();
                }
            }).catch(err => {
                console.log(err);
                history.push("/login")
            })
        } else {
            setShowDeleteModal(false);
            setGroupForDelete(undefined);
        }
    }

    const handleBulkUserRowClicked = (group: IGroup) => {
        setGroupForUsers(group);
        setShowBulkUserModal(true);
    }

    const handleBulkUserModal= (users: Array<string> | undefined) => {

        if (users !== undefined) {
            axios.post(process.env.REACT_APP_API_LOCATION +"/api/group/bulkadd", {group: groupForUsers, users: users}).then((data) => {
                if (data.status === 200) {
                    setShowBulkUserModal(false);
                    setGroupForUsers(undefined);
                    fetchGroups();
                }
            }).catch(err => {
                console.log(err);
                history.push("/login")
            })
        } else {
            setShowBulkUserModal(false);
            setGroupForUsers(undefined);
        }
    }

    return <div>
    <NewGroupModal open={showNewGroupModal} handleClose={handleNewGroupModalClose}/>
    {groupForDelete !== undefined ? <DeleteGroupModal open={showDeleteModal} group={groupForDelete} handleClose={handleDeleteGroupModal}/> : <></>}
    {groupForUsers !== undefined ? <BulkAddUserGroupModal open={showBulkUserModal} group={groupForUsers} users={users} handleClose={handleBulkUserModal} /> : <></> }
    <Paper style={{margin: '20px'}}> 
    <Grid alignItems={"center"} justify={"flex-end"} style={{paddingTop: '10px', paddingRight: '15px'}} container>
    <Paper>
        <Grid alignItems={"center"} style={{width: '250px', paddingLeft:'10px'}} container>
            <Grid item><SearchIcon/></Grid>
            <Grid item><TextField style={{width: '200px'}} value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)}>Search</TextField></Grid>
        </Grid>
    </Paper>
</Grid>
    <Table size={"small"}>
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell align={"center"}>Edit</TableCell>
                <TableCell align={"center"}>Users</TableCell>
                <TableCell align={"center"}>Delete</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {
                groupsFilter().slice(page * groupsPerPage,(page*groupsPerPage)+groupsPerPage).map((g, index) => {
                return <GroupsTableRow key={`gtr-${index}`} group={g} triggerReload={fetchGroups} deleteGroup={handleDeleteRowClicked} bulkAddGroup={handleBulkUserRowClicked}/>
                })
            } 
            {
                emptyRows > 0 && (
                    <TableRow style={{ height: 42 * emptyRows }}>
                        <TableCell colSpan={5}/>
                    </TableRow>
                )
            }
        </TableBody>
        <TableFooter>
        <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        colSpan={10}
                        count={groupsFilter().length}
                        rowsPerPage={groupsPerPage}
                        page={page}
                        SelectProps={{
                            inputProps: { 'aria-label': 'rows per page' },
                            native: true,
                        }}
                        onChangePage={(e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)}
                        onChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {setGroupsPerPage(parseInt(e.target.value, 10)); setPage(0)}}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableRow>
        </TableFooter>
    </Table>
</Paper>
    <Fab color="primary"  className={classes.fab} onClick={(e) => (setShowNewGroupModal(true))}>
    <AddIcon />
    </Fab>
</div>

}

export default Groups;
