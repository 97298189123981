import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles, useTheme, Theme, Dialog, DialogTitle, DialogContent, DialogContentText, Typography, DialogActions, Button, FormControl, InputLabel, Select, Input, Chip, MenuItem } from '@material-ui/core';
import IGroup from '../Interfaces/IGroup';
import IUser from '../Interfaces/IUser';
//import axios from 'axios';

interface Props { 
    open: boolean;
    group: IGroup;
    users: IUser[];
    handleClose: (users: string[] | undefined) => void;
}

const useStyles = makeStyles((theme) => createStyles({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
      chips: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      chip: {
        margin: 2,
      },
}));

const BulkAddUserGroupModal: React.FC<Props> = (props) => {
    const [users, setUsers] = useState<string[]>([]);
    const classes = useStyles();
    const theme = useTheme();
    
    function getStyles(user: string, users: string[], theme: Theme) {
        return {
          fontWeight:
            users.indexOf(user) === -1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
        };
      }
      
      
    
    useEffect(() => {
        const membersOf = props.group.membersOf ?? [];
        setUsers(membersOf);
        console.log(props.group.membersOf);
    }, [props.group]);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setUsers(event.target.value as string[]);
      };



    return <Dialog open={props.open} onClose={() => props.handleClose(undefined)}>
    <DialogTitle id="form-dialog-title">Bulk Modify Users/Groups</DialogTitle>
    <DialogContent>
        <DialogContentText>
            <Typography>Setting users for {props.group.name}?</Typography>
        </DialogContentText>
            <FormControl className={classes.formControl}>
            <InputLabel id="selected-users-input">Selected Users</InputLabel>
            <Select
            labelId="selected-users-input"
            id="selected-users-input-chips"
            multiple
            value={users}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
                <div className={classes.chips}>
                {(selected as string[]).map(value => (
                    <Chip key={value} label={value} className={classes.chip} />
                ))}
                </div>
            )}
            MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
            >
            {props.users.map(tuser => (
                <MenuItem key={tuser.name} value={tuser.name} style={getStyles(tuser.name, users, theme)}>
                    {tuser.name}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => props.handleClose(undefined)} color="primary">
            Cancel
        </Button>
        <Button onClick={() => props.handleClose(users)} color="secondary" variant={"contained"}>
            Set Users
        </Button>
    </DialogActions>
</Dialog>
}

export default BulkAddUserGroupModal;